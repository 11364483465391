import dynamic from 'next/dynamic';

const pages = {
  'home.HomePage': dynamic(() => import('./HomePage')),
  'flex.FlexPage': dynamic(() => import('./FlexPage')),
  'forms.BaseFormPage': dynamic(() => import('./FormPage')),
  'forms.FlexFormPage': dynamic(() => import('./FormPage')),
  'news.NewsListingPage': dynamic(() => import('./NewsListingPage')),
  'news.NewsPage': dynamic(() => import('./NewsPage')),
  'lexicon.LexiconPage': dynamic(() => import('./LexiconPage')),
  'lexicon.LexiconEntryPage': dynamic(() => import('./LexiconEntryPage')),
  'events.Event': dynamic(() => import('./EventPage')),
  'events.EventListingPage': dynamic(() => import('./EventListingPage')),
  'flex.FlexUebersichtPage': dynamic(() => import('./FlexOverview')),
  'contact.ContactListingPage': dynamic(() => import('./ContactListingPage')),
  'contact.ContactPage': dynamic(() => import('./ContactPage')),
  'tasks.TaskListingPage': dynamic(() => import('./TaskListingPage')),
  'tasks.Task': dynamic(() => import('./TaskPage')),
  'tasks.FormTask': dynamic(() => import('./FormTask')),
  'shop.ShopPage': dynamic(() => import('./ShopPage')),
  'shop.ArticleItemPage': dynamic(() => import('./ArticleItemPage')),
  'shop.ArticleItemGroupPage': dynamic(() => import('./ArticleItemGroupPage')),
  'note.NoteListingPage': dynamic(() => import('./NoteListingPage')),
  'note.NotePage': dynamic(() => import('./NotePage')),
  'shop.ShopOrderPage': dynamic(() => import('./ShopOrderPage')),
  'my_milk.LabDataPage': dynamic(() => import('./LabDataPage')),
  'my_milk.MilkMoneyPage': dynamic(() => import('./MilkMoneyPage')),
  'my_milk.AccountingDataPage': dynamic(() => import('./AccountingDataPage')),
  'my_milk.YearlyValuesPage': dynamic(() => import('./YearlyValuesPage')),
  'my_milk.SpecialSamplesPage': dynamic(() => import('./SpecialSamplesPage')),
  'supplier.GroupManagementPage': dynamic(() => import('./GroupManagementPage')),
  'supplier.PlotPage': dynamic(() => import('./PlotPage')),
  'supplier.MyFarmPage': dynamic(() => import('./MyFarmPage')),
  'supplier.MilkingTimesPage': dynamic(() => import('./MilkingTimesPage')),
  'pasture_calendar.CalendarPage': dynamic(() => import('./CalendarPage')),
  'supplier.PlotMapPage': dynamic(() => import('./PlotMapPage')),
  'kpm.KPMPage': dynamic(() => import('./KPMPage')),
  'flex.DocumentsProPage': dynamic(() => import('./DocumentsProPage')),
  'werder_bremen.WerderBremen': dynamic(() => import('./WerderBremenPage')),
  'events.MultipleEventRegistrationsPage': dynamic(
    () => import('./MultipleEventRegistrationsPage'),
  ),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
  'qm_milch.NachhaltigkeitsmodulPage': dynamic(() => import('./QmMilkPage')),
};

export type Pages = keyof typeof pages;

export default pages;
